<script setup>
import { computed } from "vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import { Head } from "@inertiajs/vue3";

const props = defineProps({ status: Number, message: String });

const title = computed(() => {
    return {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        404: "404: Page Not Found",
        403: "403: Forbidden",
    }[props.status];
});

const description = computed(() => {
    return {
        // 503: "Sorry, we are doing some maintenance. Please check back soon (in an hour or two).",
        503: "Sorry, we're currently undergoing maintenance. Please check back shortly (in a minute or so)",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
    }[props.status];
});
</script>

<template>
    <AppLayout>
        <Head :title="`Error ${title}`" />

        <template #header>
            <h1>&nbsp;</h1>
        </template>
        <div
            class="mx-auto my-10 flex max-w-7xl flex-col rounded-xl bg-white p-8 px-4 pt-6 leading-8 sm:justify-center sm:px-6 lg:px-8"
        >
            <h1 v-if="props.message.includes('Laravel\\Jetstream\\TeamInvitation')">
                This Team invitation is expired. Please ask the organizer to resend the invitation. <br />
                <br />
                <br />
            </h1>
            <h3
                v-if="props.message"
                class="pb-10"
            >
                {{ props.message }}
            </h3>
            <h3 class="pb-2">{{ description }}</h3>

            <h3>{{ title }}</h3>
        </div>
    </AppLayout>
</template>
